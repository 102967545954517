/*
 * CSS
 */
.platformSetting {
  position: relative;
  .profilePictureWrapper {
    top: 0;
    left: 0;
    & img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: 1px solid white;
      background-color: rgba(255, 255, 255, 0.33);
    }
  }
  .actionWrapper {
    gap: 6px;
    margin: 12px 0 12px 6px;
    & button {
      color: white;
      &:first-child {
        padding-right: 6px;
      }
      &:last-child {
        padding-left: 12px;
        border-left: 1px solid white;
      }
    }
  }
}