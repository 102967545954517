/*
* CSS
*/
.mccAndMncSelectorForLcr {
  .chakra-form-control > div {
    justify-content: flex-end;
  }
  .chakra-select__wrapper > select,
  .chakra-form-control .chakra-button {
    position: relative;
    padding-right: 12px;
  }
}