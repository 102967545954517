/*
 * CSS
 */
.memoizedInput {
    .rti--container {
        background-color: transparent !important;
        border-width: 0 !important;
        border-radius: 12px !important;
        max-height: 540px !important;
        overflow-y: auto !important;
        color: inherit !important;
    }

    .rti--tag {
        background-color: rgba(0, 0, 0, 0.1);
        border-width: 0;
        border-radius: 8px;
        padding: 0 8px;
        margin: 0 4px;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        color: inherit;
        font-weight: 600;
    }

    .rti--input {
        background-color: transparent;
        border-width: 0;
        border-radius: 12px;
        padding: 0 8px;
        margin: 0 4px;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        color: inherit;
        font-weight: 400;
        outline: none;

        &::placeholder {
            color: inherit !important;
            font-weight: 400;
        }
    }

    .disabled .rti--container {
        opacity: 0.5 !important;
    }

    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--chakra-colors-gray-50);
        border-radius: 30px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--chakra-colors-gray-100);
    }

    span.rti--tag {
        height: unset !important;
        margin: unset !important;
    }

    .searchSelectDropDown {
        position: absolute;
        width: 100%;
        outline: 2px solid transparent;
        outline-offset: 2px;
        background: #fff;
        box-shadow: var(--chakra-shadows-sm);
        color: inherit;
        min-width: 300px;
        padding-top: var(--chakra-space-2);
        padding-bottom: var(--chakra-space-2);
        z-index: 1;
        border-radius: 12px;
        border-width: 1px;
        height: 250px;
        overflow: scroll;
    }
}