/*
CSS
*/
.customerBlockBetweenDuration {
    select.react-time-picker__inputGroup__input.react-time-picker__inputGroup__amPm,
    input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__second,
    input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute,
    input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
        height: unset !important;
        background-color: transparent;
        font-weight: 500;
        color: var(--chakra-colors-purple-600);
    }

    select.react-time-picker__inputGroup__input.react-time-picker__inputGroup__amPm {
        color: var(--chakra-colors-purple-600);
    }

    .react-time-picker__wrapper {
        background-color: var(--chakra-colors-purple-50);
        color: var(--chakra-colors-purple-600);
        border: none;
        padding: 2px 12px;
        border-radius: 12px;
        font-weight: 500;
        font-family: Poppins;
        display: flex;
        gap: 6px;
        align-items: center;

        & ::placeholder {
            color: var(--chakra-colors-purple-600);
        }

        .react-time-picker__inputGroup {
            min-width: unset;
        }

        .react-time-picker__inputGroup__input
            react-time-picker__inputGroup__hour {
            background-color: var(--chakra-colors-purple-50);
            padding: 6px;
        }

        .react-time-picker__inputGroup__input:invalid {
            background-color: var(--chakra-colors-purple-50);
            color: var(--chakra-colors-purple-600);

            &::placeholder {
                color: var(--chakra-colors-purple-600);
            }
        }

        .react-time-picker__clock-button.react-time-picker__button,
        .react-time-picker__clear-button.react-time-picker__button {
            & svg {
                stroke: var(--chakra-colors-purple-600);
            }
        }
    }
}
