/*
 * CSS
 */
.messageTesting {
  .heading {
    & svg {
      display: unset;
      padding: 2px;
      margin: -2px 5px;
      top: 2px;
      position: relative;
    }
  }

  .chakra-form__error-message {
    display: none;
  }

  & .chakra-form__label,
  & [class*="chakra-menu__menu-button"],
  & .chakra-label {
    color: white !important;
  }

  & .searchSelectDropDown {
    z-index: 1000;
  }
}