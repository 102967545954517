/*
 * CSS
 */
.nothingFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
}