/*
 * CSS
 */
.apiCollectionAndTokens {
  .codePenContainer {
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    & > div, & > div > iframe {
      height: 100%;
    }
  }
  .heading {
    & svg {
      display: unset;
      padding: 2px;
      margin: -2px 5px;
    }
  }
}