/*
CSS
*/
.apiDocumentation {
  display: flex;
  justify-content: flex-end;
  padding: 44px 22px;

  .markDownContainer {
    width: 100%;
    margin: 0;

    & h1, h2, h3, h4, h5, h6, p {
      color: #101010;
      line-height: 2;
    }

    &>p>svg {
      width: 200px;
      height: 100px;
      position: relative;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      text-align: center;
    }

    p {
      line-height: 2.3;
      margin: 0;
      font-size: 18px;
    }

    & .heading {
      font-size: 32px !important;
      font-weight: 900;
      font-family: "Poppins", monospace;
      font-optical-sizing: auto;
      margin-bottom: 0;

      &.weight900 {
        font-weight: 900;
      }
    }

    & .heading~* {
      position: relative;
      margin-top: 0;
      margin-bottom: 22px;

      &~.subHeading {
        padding: 5px 20px;
      }
    }

    & .description {
      top: -22px;
      position: relative;
    }

    & a {
      font-weight: 600;
      font-size: 16px;
      color: #101010;
      position: relative;
      width: max-content;

      &::before {
        content: '';
        width: 100%;
        height: 2px;
        left: 5px;
        bottom: -2.5px;
        position: absolute;
        z-index: -1;
        background-color: #202020;
      }
    }

    & li {
      position: relative;
      left: 15px;
      color: #101010;
    }

    & * {
      margin-bottom: 20px;
    }

    & tr {
      background-color: #000;

      & td,
      & th {
        text-align: left;
        padding: 10px;
        border-bottom: 2px solid #202020;
        color: #101010;
      }
    }

    & .codeWrapper {
      border-radius: 12px;
      overflow: hidden;

      &>pre {
        margin: 0 !important;
        border: none !important;
      }
    }

    & code>span {
      display: unset;
    }

    & pre:has(> code) {
      border-radius: 22px;
      padding: 22px;
      background-color: #323232;

      & [class^='token'] {
        font-family: 'Source Code Pro', monospace;
        font-size: 16px;
        font-weight: 500;
      }
    }

    &>h1:first-child {
      font-size: 41px;
      font-weight: 900;
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    & .markDownContainer {
      width: 100%;

      & .heading {
        padding: 10px 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 5px;
  }
}