/*
 * CSS
 */
.mccAndMncFinder {
  .heading {
    & svg {
      display: unset;
      padding: 2px;
      margin: -2px 5px;
      top: 2px;
      position: relative;
    }
  }
}