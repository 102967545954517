/*
* CSS
*/
[data-letter-crap] {
	white-space: pre;
	overflow: hidden;
	font-size: 10px;
	line-height: 10px;
	font-family: monospace;
	padding: 0;
}
.RouteLogs {
  display: flex;
  flex-direction: column;
  & [name^='react-console-emulator'] {
    color: var(--chakra-colors-brand-500) !important;
    background-color: white !important;
  }
  & [name^='react-console-emulator__content'] {
    font-size: 13px !important;
    padding: 22px 0 !important;
    color: var(--chakra-colors-gray-500) !important;
  }
  & [name^='react-console-emulator__input'] {
    font-size: 13px !important;
    position: relative;
    top: 1.5px !important;
    font-weight: 800;
    color: var(--chakra-colors-gray-500) !important;
  }
  & .react-console-emulator__promptLabel {
    color: var(--chakra-colors-brand-500) !important;
  }
  & .react-terminal-window-buttons {
    display: none;
  }
}
.systemInformationWrapper {
  display: flex;
  padding: 22px 0;
  font-family: "JetBrains Mono", monospace;
  align-items: center;
  & #LetterCrap {
    color: var(--chakra-colors-gray-500);
  }
  & .systemInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 22px;
    flex: 1;
    & .systemInformation__title {
      font-size: 17px;
      width: 100%;
      font-weight: 400;
      margin-bottom: 12px;
      letter-spacing: 0.5px;
      color: var(--chakra-colors-gray-500);
    }
    & .systemInformation__content {
      font-size: 13px;
      padding: 0 0 6px;
      color: var(--chakra-colors-gray-400);
      font-weight: 500;
    }
  }
}